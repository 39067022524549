<template>
  <div class="home-box">
    <div class="top-box">
      <div class="child">
        <img src="@/assets/img/今日需求.png" alt="">
        <div>
          <p>今日订单</p>
          <p>{{buyStatisticsData.dayCount}}</p>
        </div>
      </div>
      <div class="child">
        <img src="@/assets/img/今日付款.png" alt="">
        <div>
          <p>本月订单</p>
          <p>{{buyStatisticsData.monthCount}}</p>
        </div>
      </div>
      <div class="child">
        <img src="@/assets/img/本月需求.png" alt="">
        <div>
          <p>今日退款</p>
          <p>{{returnStatisticsData.dayCount}}</p>
        </div>
      </div>
      <div class="child">
        <img src="@/assets/img/本月付款.png" alt="">
        <div>
          <p>本月退款</p>
          <p>{{returnStatisticsData.monthCount}}</p>
        </div>
      </div>
    </div>
    <p class="title" v-if="false">代办审批</p>
    <div class="ban-box" v-if="false">
      <div class="approve" v-for="item in list" @click="gotoApprove(item)" :key="item">
        <div class="approve-top">
          <span><i class="icon" :style="{background:item.state == 2 ?'#F14F44':item.state == 1 ?'#FFB648':'#2a38fe'}"></i>{{item.type==0?'需求':'付款'}}</span>
          <span>{{item.createTime}}</span>
        </div>
        <h3 :style="{color:item.state == 2 ?'#F14F44':'#000'}">{{item.createByName}}提交的{{item.type==0?'需求':'付款'}}审批 {{item.state == 0 ? '' :item.state == 1 ? '抄送给我' : item.state == 2 ? `被${item.approvalName}退回` : ''}}</h3>
        <p>
          <span>客户：</span>
          <span>{{item.customerName}}</span>
        </p>
        <p>
          <span>委托经营所得：</span>
          <span style="color:#F14F44">{{item.money}} 元</span>
        </p>
        <p>
          <span>类型：</span>
          <span>{{item.businessType==0?'垫资':'不垫资'}} + {{item.isIntegral==0?'积分':'非积分'}}</span>
        </p>
        <p>
          <span>业务条件：</span>
          <span>{{item.businessType == 0 ? `${item.serviceCharge} (${item.interestFreeDays})+${item.ratio}` : item.serviceCharge}}</span>
        </p>
      </div>
    </div>
    <el-empty description="暂无代办审批" v-if="false" style="margin-top:150px"></el-empty>
  </div>
</template>
<script>
import { getPayCountStatistics, getRefundCountStatistics } from '@/api/index'
export default {
  name: 'default',
  data() {
    return {
      form: {},
      list: [],
      buyStatisticsData:{},
      returnStatisticsData:{}
    }
  },
  created() {
    this.getPayCountStatistics();
    this.getRefundCountStatistics();
    //this.onload()
  },
  filters: {

  },
  methods: {
    getPayCountStatistics(){
      getPayCountStatistics({}).then(res => {
        if (res.code != 200) return
        this.buyStatisticsData = res.data; 
      })
    },
     getRefundCountStatistics(){
      getRefundCountStatistics({}).then(res => {
        if (res.code != 200) return
        this.returnStatisticsData = res.data;
        
      })
    },
    gotoApprove(item) {
      this.$router.push({
        path: '/approve/index',
        query: {
          id: item.id,
          editType: item.state == 0 ? 'approve' : 'show',
          type: item.type
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.home-box {
  padding: 20px 0 20px 20px;
  .title {
    font-weight: bold;
    font-size: 20px;
    margin: 20px 0 0;
  }
  .top-box {
    // background: red;
    display: flex;
    justify-content: space-between;
    color: #fff;
    .child {
      flex: 1;
      height: 137px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      box-shadow: 0px 10px 20px rgba(111, 108, 255, 0.25);
      border-radius: 10px;
      img {
        width: 48px;
        height: 48px;
        margin: 0 22px;
      }
      p:nth-child(1) {
        font-weight: normal;
        font-size: 16px;
        margin: 0;
      }
      p:nth-child(2) {
        font-weight: bold;
        font-size: 26px;
        margin: 6px 0 0 0;
      }
    }
    .child:nth-child(1) {
      background: #4a93ff;
    }
    .child:nth-child(2) {
      background: #ffa439;
    }
    .child:nth-child(3) {
      background: #865bff;
    }
    .child:nth-child(4) {
      background: #f14f44;
    }
  }
}
.ban-box {
  display: flex;
  flex-wrap: wrap;
  .approve {
    flex: 1;
    flex-shrink: 0;
    min-width: 380px;
    max-width: 395px;
    height: 269px;
    filter: drop-shadow(0px 15px 50px rgba(160, 163, 189, 0.13));
    background: #fff;
    margin: 20px 20px 0 0;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 10px 12px;
    cursor: pointer;
    &:hover {
      filter: drop-shadow(0px 15px 50px rgba(160, 163, 189, 0.349));
      transition: filter 0.5s;
    }
    .approve-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-left: -5px;
        margin-right: 5px;
        border-radius: 50%;
      }
      span:nth-child(2) {
        color: #666;
        font-size: 14px;
      }
    }
    p {
      span:nth-child(1) {
        color: #000;
        font-size: 16px;
      }
      span:nth-child(2) {
        color: #666;
        font-size: 14px;
      }
    }
  }
}
</style>